import * as React from "react";
import Victor from "victor";
import {Debug} from "../Debug";
import {COLLISION_RADIUS, FRAME_SIZE, SHIP_ROTATION, SHIP_THRUST} from "../simulator/constants";
import {Asteroid, makeAsteroid} from "../simulator/model/asteroid";
import {Shell} from "../simulator/model/shell";
import {projectShipInTime, Ship} from "../simulator/model/ship";
import {Hit, World} from "../service/World";
import {generateWall, Wall} from "../simulator/simulator";
import {useAnimationFrame} from "../util";
import {CanvasState} from "./Canvas";
import {V, V2, VectorToObject} from "../simulator/model/vector";
import voronoi from "voronoi-diagram";
// @ts-ignore
import * as Voronoi from "voronoi";
import Flatten from "@flatten-js/core";
import {createAsteroidPolygon, createWallPolygon} from "../simulator/polygon-util";

function calculateVoronoiDiagram(points: any) {
  // Calculate Voronoi diagram of points
  const voronoi = new Voronoi();
  const bbox = { xl: -200, xr: 800, yt: -200, yb: 800 }; // Set bounding box
  const diagram = voronoi.compute(points, bbox);

  // Return Voronoi diagram
  return diagram;
}

function getPolygonVertices(cell: any) {
  // Get vertices of polygon from Voronoi cell halfedges
  const vertices: any = [];
  cell.halfedges.forEach((halfedge: any) => {
    vertices.push([halfedge.edge.va.x, halfedge.edge.va.y]);
  })

  // Close polygon by adding first vertex again
  vertices.push(vertices[0]);

  // Return polygon vertices
  return vertices;
}

function fracturePolygonWithVoronoi(coords: any) {
  // Convert x and y coordinates into an array of objects
  const points = coords.map((coord: any) => ({ x: coord[0], y: coord[1] }));

  // Calculate Voronoi diagram of points
  const diagram = calculateVoronoiDiagram(points);

  // Create array to store polygon pieces
  const pieces: any = [];

  // Loop through Voronoi cells
  diagram.cells.forEach((cell: any, i: any) => {
    // Get vertices for this polygon
    const vertices = getPolygonVertices(cell);

    // Create polygon object for this piece
    const piece = { vertices };

    // Add polygon object to array of pieces
    pieces.push(piece);
  });

  // Return array of polygon pieces
  return pieces;
}

// Example usage
// const polygon = [[100, 100], [500, 200], [700, 500], [200, 400]];
// const pieces = fracturePolygonWithVoronoi(polygon);
// console.log(pieces);

export interface Point {
  x: number
  y: number
}

const calculateCentreFromPlayer = (ship: Ship, width: number, height: number) => {
    return {x: Math.round(ship.position.x / width) * width, y: Math.round(ship.position.y / height) * height}
}

// Really simple UPS counter
let oldUps = 0;
let updated = 0;
let ups = 0;
setInterval(() => {
  ups = updated - oldUps;
  oldUps = updated;
}, 1000);

const MainView: React.FC<{
  canvas: CanvasState,
  world: World,
  debug: Debug,
  playerId: string,
}> = ({canvas, world, debug, playerId}) => {
  // @TODO: Use delta to render actual position of objects
  useAnimationFrame(deltaMs => {
    // TODO: This won't work if deltaMs / Framesize rounds down to 0
    //const frame = world.frame = world.frame + Math.floor(deltaMs / FRAME_SIZE);
    const frame = world.frame += 1
    updated = world.updated;
    //const frame = timeToFrame(Date.now())
    const ships = world.ships(frame)
    const shells = world.shells(frame)
    const hits = world.hits(frame)
    const asteroids = world.asteroids(frame)
    let playerShip = world.getPlayerShip(playerId)
    if (!playerShip) return;
    playerShip = projectShipInTime(playerShip, frame)
    const centre = calculateCentreFromPlayer(playerShip, canvas.width, canvas.height)
    const drawer = canvasDrawer(canvas.ctx, centre)
    drawer.text(debug.debug(), 10, 10, "gray")
    drawer.text(`UPS: ${ups}`, 10, 25, "gray")
    drawer.text(String(world.data.asteroids.reduce((s, x) => s + x.mass, 0)), 10, 65, "gray")
    drawer.text(JSON.stringify(world.data.shells.map(s => V2(s.velocity).angleRad())), 10, 100, "gray")
    drawer.text(JSON.stringify(world.data.asteroids.map(s => Math.round(s.mass))), 10, 115, "gray")
    drawer.text(JSON.stringify(shells.map(s => s.ttl)), 10, 200, "red")
    drawer.text("v:"+JSON.stringify(world.data.ships.map(s => projectShipInTime(s, frame).velocity)), 10, 130, "gray")
    drawer.text("p:"+JSON.stringify(world.data.ships.map(s => projectShipInTime(s, frame).position)), 10, 150, "gray")
    // world.ghostStuff().ships.forEach(s => drawer.ship(s, "gray"))
    // world.ghostStuff().shells.forEach(s => drawer.shell(s, "gray"))
    drawer.wall(world.wall)
    hits.forEach(hit => {
      drawer.hit(hit)
      //drawer.foo(hit, world.wall);
    })
    shells.forEach(s => {
      drawer.shell(s)
    })
    ships.forEach(s => {
      drawer.ship(s)
      // drawer.collisionMesh(s)
    })
    asteroids.forEach(a => {
      drawer.asteroid(a)
    })
    //drawer.voronoiDiagramTest();
    //drawer.voronoiTest();
  })
  return null
}

// TODO: Solve half pixel bug
const canvasDrawer = (ctx: CanvasRenderingContext2D, centre: Point) => {
  const width = ctx.canvas.clientWidth
  const height = ctx.canvas.clientHeight
  const local = (p: Point): Point => {
    return ({
      x: p.x - centre.x + width/2,
      y: p.y - centre.y + height/2
    })
  }
  ctx.font = '12pt Mono'
  ctx.clearRect(0, 0, width, height);

  const drawVerticalLine = (x: number) => {
    ctx.moveTo(x+.5, 0)
    ctx.lineTo(x+.5, height)
  }
  const drawHorizontalLine = (y: number) => {
    ctx.moveTo(0, y+.5)
    ctx.lineTo(width, y+.5)
  }

  const shipBodyParts = [
    new Victor(10, 0),
    new Victor(-5, -5),
    new Victor(-5, 5)
  ]
  const thrustConeParts = [
    new Victor(-5, -2),
    new Victor(-10, 0),
    new Victor(-5, 2),
  ]

  return {
    voronoiDiagramTest() {
      // var sites = new Array(10)
      // for(var i=0; i<10; ++i) {
      //   sites[i] = [Math.random() * 1000, Math.random() * 1000]
      // }
      const sites = generateWall(100, 20).map(x => {
        const a = V(x[0], x[1]).addScalar(300)
        return [a.getX(), a.getY()]
      })
      sites.pop()
      const a = voronoi(sites)
      const cells = a.cells
      const points = a.positions
      for(var i=0; i<cells.length; ++i) {
        var cell = cells[i]
        if(cell.indexOf(-1) >= 0) {
          continue
        }
        ctx.strokeStyle = "white" 
        ctx.beginPath()
        ctx.moveTo(points[cell[0]][0], points[cell[0]][1])
        for(var j=1; j<cell.length; ++j) {
          ctx.lineTo(points[cell[j]][0], points[cell[j]][1])
        }
        ctx.closePath()
        ctx.stroke()
        //ctx.fill()
      }

      for(var i=0; i<sites.length; ++i) {
        ctx.beginPath()
        ctx.arc(sites[i][0], sites[i][1], 5.0, 0, 2*Math.PI)
        ctx.closePath()
        ctx.stroke()
      }
    },
    voronoiTest() {
      const sites = generateWall(100, 11).map(x => {
        const a = V(x[0], x[1]).addScalar(300)
        //return {x: a.getX(), y: a.getY()}
        return [a.getX(), a.getY()]
      })
      sites.pop()

      sites.forEach(site => {
        ctx.beginPath();
        //ctx.arc(site.x, site.y, 5, 0, 2*Math.PI);
        ctx.arc(site[0], site[1], 5, 0, 2*Math.PI);
        ctx.closePath()
        ctx.stroke();
      })

      //const pieces = fracturePolygonWithVoronoi(sites);

      // pieces.forEach((piece: any) => {
      //   ctx.beginPath();
      //   ctx.moveTo(piece.vertices[0][0], piece.vertices[0][1]);
      //   piece.vertices.forEach((vert: any) => {
      //     ctx.lineTo(vert[0], vert[1]);
      //   })
      //   ctx.closePath();
      //   ctx.fillStyle = "red";
      //   ctx.fill();
      // })
    },
    asteroid: (a: Asteroid) => {
      ctx.lineWidth = 1
      ctx.strokeStyle = "white"
      ctx.beginPath()
      for (const [x, y] of a.shape) {
        const l = local(VectorToObject(V(x, y).rotateByRad(a.bearing).add(V2(a.position))))
        ctx.lineTo(l.x, l.y)
      }
      ctx.closePath()
      ctx.stroke()
    },
    wall: (w: Wall) => {
      ctx.lineWidth = 1
      ctx.strokeStyle = "white"
      ctx.beginPath()
      for (const [x, y] of w) {
        const l = local({x, y})
        ctx.lineTo(l.x, l.y)
      }
      ctx.closePath()
      ctx.stroke()
    },
    ship: (s: Ship, fillStyle: string = "white") => {
      const l = local(s.position)
      const localVector = new Victor(l.x, l.y)

      // Ship
      ctx.lineWidth = 1
      ctx.strokeStyle = fillStyle
      ctx.fillStyle = "black"

      const [nose, left, right] = shipBodyParts.map(v => v.clone().rotate(s.bearing).add(new Victor(l.x, l.y)))
      ctx.beginPath()
      // nose
      ctx.moveTo(nose.x, nose.y)
      // left
      ctx.lineTo(left.x, left.y)
      // right
      ctx.lineTo(right.x, right.y)
      ctx.lineTo(nose.x, nose.y)
      ctx.closePath()
      ctx.fill()
      ctx.stroke()

      // thrust cone
      if (s.thrust > 0) {
        const [left, tip, right] = thrustConeParts.map(v => v.clone().rotate(s.bearing).add(localVector))
        ctx.beginPath()
        ctx.moveTo(left.x, left.y)
        ctx.lineTo(tip.x, tip.y)
        ctx.lineTo(right.x, right.y)
        ctx.stroke()
      }
    },
    collisionMesh: (s: Ship) => {
      const l = local(s.position)
      ctx.strokeStyle = "red"
      ctx.beginPath()
      ctx.arc(l.x, l.y, COLLISION_RADIUS, 0, 2 * Math.PI)
      ctx.stroke()
    },
    debugVelocity: (s: Ship) => {
      const l = local(s.position)
      // Debug velocity vector
      ctx.beginPath()
      ctx.moveTo(l.x, l.y)
      ctx.lineTo((s.velocity.x * 100 + l.x), (s.velocity.y * 100 + l.y))
      ctx.stroke()
    },
    shell: (s: Shell, fillStyle: string = "white") => {
      ctx.lineWidth = 1
      ctx.strokeStyle = fillStyle
      ctx.beginPath()
      const l = local(s.position)
      const shellTip = VectorToObject(V(5, 0).rotateByRad(V2(s.velocity).angleRad()).add(V2(l)))
      ctx.moveTo(l.x, l.y)
      ctx.lineTo(shellTip.x, shellTip.y)
      ctx.stroke()
    },
    hit: (hit: Hit) => {
      ctx.lineWidth = 1
      const d = Math.round((1-hit.phase)*255).toString(16).padStart(2, "0")
      ctx.strokeStyle = `#${d}${d}${d}`
      const l = local(hit.position)
      ctx.beginPath()
      ctx.arc(l.x, l.y, hit.phase*20, 0, 2 * Math.PI)
      ctx.stroke()

      // ctx.beginPath()
      // ctx.moveTo(l.x, l.y)
      // ctx.lineTo(l.x + hit.shellHit.vector.x, l.y + hit.shellHit.vector.y);
      // ctx.stroke()
    },
    foo: (hit: Hit, w: Wall) => {
      // ctx.lineWidth = 1
      // const wall = createSimpleWallPolygon(w);
      // const r = createAsteroidPolygon(hit.position.x, hit.position.y);
      //
      // // const wall = new Polygon(new Flatten.Box(0, 0, 100, 100))
      // //let r= new Polygon(new Flatten.Box(-10, -10, 10, 10));
      // //r = r.translate(vector(hit.position.x, hit.position.y))
      // const newWall = Flatten.BooleanOperations.unify(wall, r);
      // console.assert(newWall.faces.size > 0, "New wall has no faces");
      // console.log(`Wall hit, wall has ${newWall.faces.size} faces`);
      // if (newWall.faces.size < 1) {
      //   return;
      // }
      // const face = [...newWall.faces][0]
      // const points = [...face.edges].map(edge => edge.start);
      //
      // //const overlap = Flatten.BooleanOperations.intersect(wall, r);
      // const drawPoint = (a: Point, s: string) => {
      //   ctx.strokeStyle = s
      //   ctx.beginPath()
      //   const l = local(a);
      //   ctx.arc(l.x, l.y, 2, 0, 2 * Math.PI);
      //   ctx.stroke();
      // }
      // points.forEach(a => {
      //   drawPoint({x: a[0], y: a[1]}, "red");
      // })
      //
      // ctx.beginPath()
      // ctx.strokeStyle = "blue"
      // for (const {x, y} of points) {
      //   const l = local({x, y})
      //   ctx.lineTo(l.x, l.y)
      // }
      // ctx.closePath()
      // ctx.stroke()
    },
    centreGraduation: () => {
      ctx.strokeStyle = "#333333"
      ctx.lineWidth = 1
      ctx.beginPath()
      ctx.moveTo(0, Math.floor(height/2)+0.5)
      ctx.lineTo(width, Math.floor(height/2)+0.5)
      ctx.moveTo(Math.floor(width/2)+0.5, 0)
      ctx.lineTo(Math.floor(width/2)+0.5, height)
      ctx.stroke()
    },
    text: (text: string, x: number, y: number, colour: string = "white") => {
      ctx.fillStyle = colour
      ctx.fillText(text, x, y)
    },
    grid: (tileSize: number = 100) => {
      ctx.strokeStyle = "#555555"
      ctx.lineWidth = 1
      ctx.beginPath()
      for (let i = 0; i < width + tileSize; i+= tileSize) {
        let x = Math.floor(i + (width/2 - centre.x) % tileSize)
        drawVerticalLine(x)
      }
      for (let i = 0; i < height + tileSize; i+= tileSize) {
        let y = Math.floor(i + (height/2 - centre.y) % tileSize)
        drawHorizontalLine(y)
      }
      ctx.stroke()

      ctx.fillStyle = "white"
      for (let i = 0; i < width + tileSize; i+= 100) {
        let x = i + (width/2 - centre.x) % tileSize
        ctx.fillText((
          Math.ceil((centre.x - width/2 + i) /tileSize)*tileSize
        ).toFixed(0).toString(), x, 100)
      }
      for (let i = 0; i < height + tileSize; i+= 100) {
        let y = i + (height/2 - centre.y) % tileSize
        ctx.fillText((
          Math.ceil((centre.y - height/2 + i) /tileSize)*tileSize
        ).toFixed(0).toString(), 5, y)
      }
    },
    setPixel(x: number, y: number, value: number) {
      ctx.moveTo(x, y)
      ctx.fillStyle = `rgb(${value},${value},${value})`
      const l = local({x, y})
      ctx.fillRect(l.x, l.y, 1, 1)
    }
  }
}

export { MainView, useAnimationFrame, canvasDrawer }
