import {Subject} from "rxjs";
import {Asteroid, projectAsteroidInTime} from "../simulator/model/asteroid";
import {projectShellInTime, Shell, ShellHit} from "../simulator/model/shell";
import {projectShipInTime, Ship} from "../simulator/model/ship";
import {UpdateData, Wall} from "../simulator/simulator";
import {FPS} from "../simulator/constants";

export interface HitShip {
  owner: string
  position: Vector
  vector: Vector,
  target: string
}

export interface Vector {
  x: number,
  y: number,
}

export interface Hit {
  position: Vector
  phase: number
  shellHit: HitShip
}

const ScoreState = () => {
  let score = 0
  const subject = new Subject<number>()
  subject.subscribe(s => {
    score += s
  })
  return {
    currentScore: () => score,
    score: subject,
  }
}

function merge<T>(into: Array<T>, from: Array<T>, predicate: (a: T, b: T) => boolean) {
  from.forEach(a => {
    const index = into.findIndex(b => predicate(a, b));

    if (index !== -1) {
      into[index] = {...a};
    } else {
      into.push({...a});
    }
  })
}

/**
 * @TODO: Store position of objects, and works out where they are supposed to be based on the delta.
 * The server works out collisions and sends out updates often.
 * @TODO: Move the player out of the world, track it externally
 */
export class World {
  wall: Wall = [];
  frame: number = 0;
  updated = 0
  constructor() {
  }
  // Server side data
  data = {
    ships: new Array<Ship>(),
    shells: new Array<Shell>(),
    asteroids: new Array<Asteroid>(),
    // Client side data
    hits: new Array<Hit>()
  }

  // Ghost ships and shells
  _ghost: {
    ships: Ship[]
    shells: Shell[]
  } = {
    ships: [],
    shells: []
  };

  getPlayerShip(playerId: string) {
    return this.data.ships.find(s => s.id == playerId)
  }
  ghostStuff() {
    return this._ghost
  }
  addHit(hit: HitShip) {
    this.data.hits.push({position: hit.position, phase: 0, shellHit: hit})
  }
  ships(t: number) {
    // Figure out how far the ships have moved
    return this.data.ships.map(ship => projectShipInTime(ship, t))
  }
  shells(t: number) {
    // Filer out shells with expired TTL
    this.data.shells = this.data.shells.filter(shell => projectShellInTime(shell, t).ttl > 0)
    return this.data.shells.map(s => projectShellInTime(s, t))
  }
  hits(t: number) {
    // TODO: Make this work via time delta
    this.data.hits = this.data.hits.filter(h => h.phase < 1)
    this.data.hits.forEach(hit => hit.phase += 1/FPS)
    return this.data.hits
  }
  asteroids(t: number) {
    this.data.asteroids = this.data.asteroids.filter(x => x.mass > 0)
    return this.data.asteroids.map(a => projectAsteroidInTime(a, t))
  }

  removeShip(id: string) {
    const index = this.data.ships.findIndex(s => s.id === id)
    if (index !== -1) {
      delete this.data.ships[index];
    }
  }

  update(data: UpdateData) {
    this.updated++
    // Merge ships
    merge(this.data.ships, data.ships, (s1, s2) => s1.id === s2.id)
    merge(this.data.shells, data.shells, (s1, s2) => s1.id === s2.id)
    merge(this.data.asteroids, data.asteroids, (s1, s2) => s1.id === s2.id)
    //this.data.ships = data.ships
    //this.data.asteroids = data.asteroids
    if (data.wall.length > 0) {
      this.wall = data.wall
    }

    data.hits.forEach(hit => {
      this.addHit(hit)
    })
  }
  ghost(g: { ships: Ship[]; shells: Shell[] }) {
    this._ghost = g;
  }
}

