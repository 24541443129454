import {Command} from "./command";
import {Ship} from "./model/ship";
import {findSegment, generateSimpleWall, generateWall, UpdateData, Wall} from "./simulator";
import {MAX_SHELL_TTL, newShell, Shell, ShellHit} from "./model/shell";
import {Asteroid, makeAsteroid} from "./model/asteroid";
import {EventEmitter} from "events";

import {
  ActiveCollisionTypes,
  ActiveEvents,
  Ball,
  Collider,
  ColliderDesc,
  ColliderHandle,
  EventQueue, Polyline, Ray,
  RigidBody,
  RigidBodyDesc,
  Vector,
  World
} from '@dimforge/rapier2d-compat';
import {COLLISION_FUDGE, COLLISION_RADIUS, FPS, SHELL_COLLISION_RADIUS, SHIP_ROTATION, SHIP_THRUST} from "./constants";
import {Vecta} from "vecta";
import {V, V2, Va, VectorToObject} from "./model/vector";
import {
  calculateMassProperties,
  createAsteroidPolygon,
  createPolygonFromAsteroid,
  createWallPolygon,
  makeAsteroidFromPolygon
} from "./polygon-util";
import Flatten, {point, vector} from "@flatten-js/core";

export enum BodyType {
  Ship,
  Asteroid,
  Shell,
  Wall
}

export type BodyCommon = {
  id: string
  body: RigidBody
  collider: Collider
}
export type BodyShip = BodyCommon & {
  type: BodyType.Ship
  entity: Ship
}
export type BodyShell = BodyCommon & {
  type: BodyType.Shell
  entity: Shell
}
export type BodyAsteroid = BodyCommon & {
  type: BodyType.Asteroid
  entity: Asteroid
}
export type Body = BodyShip | BodyShell | BodyAsteroid

type Id = string
class Store {
  private colliderHandleMap: Map<ColliderHandle, Body> = new Map<ColliderHandle, Body>()
  private colliderIdMap: Map<Id, ColliderHandle> = new Map<Id, ColliderHandle>()

  public addBody(id: Id, body: Body) {
    const handle = body.collider.handle
    this.colliderHandleMap.set(handle, body)
    this.colliderIdMap.set(id, handle)
  }

  public removeById(id: Id): boolean {
    const handle = this.colliderIdMap.get(id)
    if (handle === undefined) return false
    const hasHandle = this.colliderHandleMap.delete(handle)
    if (hasHandle === undefined) return false
    this.colliderIdMap.delete(id)
    return true
  }

  public removeByHandle(handle: ColliderHandle): boolean {
    const id = this.colliderHandleMap.get(handle)?.id
    if (id === undefined) return false
    const hasId = this.colliderIdMap.delete(id)
    if (hasId === undefined) return false
    this.colliderHandleMap.delete(handle)
    return true
  }

  public getBodyById(id: Id): Body | undefined {
    const handle = this.colliderIdMap.get(id)
    if (handle === undefined) return undefined
    return this.colliderHandleMap.get(handle)
  }

  /**
   * Get Body by Collider handle.
   * @param handle
   */
  public getBodyByHandle(handle: ColliderHandle): Body | undefined {
    return this.colliderHandleMap.get(handle)
  }

  public getHandleFromRigidBody(body: RigidBody): ColliderHandle | undefined {
    if (body.numColliders() < 1) {
      return undefined
    }
    return body.collider(0).handle
  }
  public getIdFromRigidBody(body: RigidBody): Id | undefined {
    const handle = this.getHandleFromRigidBody(body)
    if (handle === undefined) return undefined
    return this.colliderHandleMap.get(handle)?.id
  }
  public getIdFromCollider(collider: Collider): Id | undefined {
    return this.colliderHandleMap.get(collider.handle)?.id
  }
  public getAll(): IterableIterator<Body> {
    return this.colliderHandleMap.values()
  }
}

enum CollisionType {
  ShellAsteroid,
  ShellShip,
  ShellWall,
}
type Collision = {
  type: CollisionType.ShellAsteroid
  shell: BodyShell
  asteroid: BodyAsteroid
} | {
  type: CollisionType.ShellShip,
  shell: BodyShell
  ship: BodyShip
} | {
  type: CollisionType.ShellWall,
  shell: BodyShell
}
enum ContactType {
  AsteroidAsteroid,
  AsteroidShip,
  AsteroidWall,
  ShipWall,
}
type Contact = {
  type: ContactType.AsteroidAsteroid
  asteroid1: BodyAsteroid
  asteroid2: BodyAsteroid
} | {
  type: ContactType.AsteroidShip
  asteroid: BodyAsteroid
  ship: BodyShip
} | {
  type: ContactType.AsteroidWall
  asteroid: BodyAsteroid
} | {
  type: ContactType.ShipWall
  ship: BodyShip
}
/**
 * Things that the simulator could abstract
 * - Creating and deleting physics and sensor objects
 * - Notifying of collisions
 */
export class RapierSimulator extends EventEmitter {
  public frame: number
  private readonly wall: Wall
  private readonly world: World
  private readonly store: Store = new Store()
  private readonly queue: EventQueue
  private readonly wallCollider: Collider;
  private readonly wallBody: RigidBody;

  constructor(frame: number) {
    super();
    this.frame = frame
    this.wall = generateWall(200, 40)

    this.world = new World({ x: 0, y: 0 })
    this.queue = new EventQueue(true)

    // Create the Wall
    this.wallBody = this.world.createRigidBody(
      RigidBodyDesc
        .fixed()
        .setCcdEnabled(true)
        .setCanSleep(false)
        .setUserData("wall"))
    this.wallCollider = this.world.createCollider(
      ColliderDesc
        .polyline(new Float32Array(this.wall.flatMap(x => x)))
        .setFriction(0.5)
        .setRestitution(0.2)
        .setActiveEvents(ActiveEvents.COLLISION_EVENTS)
        .setActiveCollisionTypes(ActiveCollisionTypes.KINEMATIC_FIXED),
      this.wallBody)
  }

  private createAsteroidBody(
    id: string,
    position: Vector,
    velocity: Vector,
    angularVelocity: number,
    rotation: number,
    wall: Wall) {
    let rigidBody = this.world.createRigidBody(RigidBodyDesc.dynamic()
      .setTranslation(position.x, position.y)
      .setLinvel(velocity.x, velocity.y)
      .setAngvel(angularVelocity)
      .setRotation(rotation)
      .setAngularDamping(0)
      .setCanSleep(false)
      .setCcdEnabled(true)
      .setUserData(id))

    const massProperties = calculateMassProperties(wall);
    let collider = this.world.createCollider(
      ColliderDesc
        .polyline(new Float32Array(wall.flatMap(x => x)))! // TODO: !
        .setFriction(0.5)
        .setRestitution(0.2)
        .setMassProperties(
            massProperties.mass,
            massProperties.center,
            massProperties.momentOfInertia
        )
        .setActiveEvents(ActiveEvents.CONTACT_FORCE_EVENTS),
      rigidBody)

    return [rigidBody, collider] as const
  }

  private createBody(
    id: string,
    position: Vector,
    velocity: Vector,
    angularVelocity: number,
    rotation: number,
    radius: number) {
    let rigidBody = this.world.createRigidBody(RigidBodyDesc.dynamic()
      .setTranslation(position.x, position.y)
      .setLinvel(velocity.x, velocity.y)
      .setAngvel(angularVelocity)
      .setRotation(rotation)
      .setAngularDamping(0)
      .setCanSleep(false)
      .setCcdEnabled(true)
      .setUserData(id))

    let collider = this.world.createCollider(
      ColliderDesc
        .ball(radius)
        .setFriction(0.5)
        .setRestitution(0.2)
        .setActiveEvents(ActiveEvents.CONTACT_FORCE_EVENTS),
      rigidBody)
    return [rigidBody, collider] as const
  }

  private createSensorBody(
    id: string,
    position: Vector,
    velocity: Vector,
    rotation: number) {
    const body = this.world.createRigidBody(RigidBodyDesc.kinematicVelocityBased()
      .setTranslation(position.x, position.y)
      .setLinvel(velocity.x, velocity.y)
      .setRotation(rotation)
      .setCanSleep(false)
      .setCcdEnabled(true)
      .setUserData(id))
    const collider = this.world.createCollider(
      ColliderDesc.ball(SHELL_COLLISION_RADIUS)
        .setSensor(true)
        .setActiveEvents(ActiveEvents.COLLISION_EVENTS),
      body)
    return [body, collider] as const
  }

  private createShell(shell: Shell) {
    const [body, collider] = this.createSensorBody(
      String(shell.id),
      shell.position,
      shell.velocity,
      V2(shell.velocity).angleRad())
    this.store.addBody(String(shell.id), {
      id: String(shell.id),
      body,
      collider,
      type: BodyType.Shell,
      entity: shell
    })
  }

  private createShip(id: string, frame: number): Ship {
    // const bearing = Math.random() * 2 * Math.PI
    // const position = V(Math.random()*50, 0).rotateByRad(Math.random()*2*Math.PI)
    const bearing = Math.PI;
    const position = V(0, 0);
    // TODO: Find an empty place
    const [body, collider] = this.createBody(
      id,
      VectorToObject(position), 
      {x: 0, y: 0},
      0,
      bearing,
      COLLISION_RADIUS)
    const ship: Ship = {
      id,
      frame,
      bearing,
      position: body.translation(),
      thrust: 0,
      rotation: 0,
      velocity: {x: 0, y: 0},
    }
    this.store.addBody(id, {
      id,
      body,
      collider,
      type: BodyType.Ship,
      entity: ship
    })
    return ship
  }

  private collidesWithBall(
    position: Vector,
    radius: number
  ): boolean {
    let collides = false;
    const shape = new Ball(radius + COLLISION_FUDGE);
    this.world.queryPipeline.update(this.world.bodies, this.world.colliders)
    this.world.intersectionsWithShape(position, 0, shape, _ => {
        collides = true
        return true
    })
    return collides;
  }

  private createAsteroidChunks(position: Vector, vector: Vector, collider: Collider) {
    // Cast ray from hit, and get two vertices, front and back
    // Find the segments that the vertices are on
    this.world.queryPipeline.update(this.world.bodies, this.world.colliders)
    // TODO: Max Toi should be Max diagonal of asteroid bounding box
    console.log("Collider of roid:", collider.handle)
    const ray = new Ray(position, vector)
    const intersects = this.world.castRayAndGetNormal(ray, 999, false, undefined, undefined, undefined, undefined, collider1 => {
      const b = this.store.getBodyByHandle(collider1.handle);
      return b?.type === BodyType.Asteroid;
    });
    //const intersects = this.world.castRay(ray, 999, false);
    // const intersects: RayColliderIntersection[] = [];
    // this.world.intersectionsWithRay(
    //   ray,
    //   999,
    //   false,
    //   (intersect) => {
    //     let hitPoint = ray.pointAt(intersect.toi);
    //     const body = this.store.getBodyByHandle(intersect.collider.handle);
    //     console.log("Body", body, "hit at point", hitPoint, "with normal", intersect.normal);
    //     //if (intersect.collider.handle === collider.handle) {
    //       intersects.push(intersect);
    //     //}
    //     return true;
    //   });
    console.log(intersects);
    if(intersects !== null) {
      const hit = ray.pointAt(intersects.toi);
      const r2 = new Ray(hit, vector); // @TODO: Randomise the new vector a little
      const i2 = this.world.castRayAndGetNormal(r2, 999, false, undefined, undefined, undefined, undefined, c => {
        return this.store.getBodyByHandle(c.handle)?.type === BodyType.Asteroid;
      });
      console.log(i2);
    }
  }

  private crackPolygonAsteroid(body: BodyAsteroid, hit: ShellHit, data: UpdateData) {
    // Remove roid from physics engine
    this.updateAsteroid(body);
    this.world.removeRigidBody(body.body)
    this.store.removeById(body.id)
    data.asteroids.push({...body.entity, mass: 0})

    // draw a hole in existing asteroid polygon
    // Center the polygon back in place
    const polygon = createPolygonFromAsteroid(body.entity)
        .translate(vector(point(), point(body.entity.position.x, body.entity.position.y)))
        .rotate(body.entity.bearing, point(body.entity.position.x, body.entity.position.y))
    const shot = createAsteroidPolygon(hit.position.x, hit.position.y)
    const newRoid = Flatten.BooleanOperations.subtract(polygon, shot);
    const faces = [...newRoid.faces] as Flatten.Face[];

    // For each face, re-create as new roids
    for (const face of faces) {
      // Push update
      const roid = makeAsteroidFromPolygon(this.frame, face.toPolygon());
      roid.rotation = body.entity.rotation;
      roid.velocity = body.entity.velocity;
      roid.bearing = body.entity.bearing;
      this.updateAsteroid(this.createAsteroid(roid));
      data.asteroids.push(roid);
    }
  }

  // @TODO: Replace with better cracking logic
  private crackAsteroid(body: BodyAsteroid, hit: ShellHit, data: UpdateData) {
    this.createAsteroidChunks(hit.position, hit.vector, body.collider);
    this.world.removeRigidBody(body.body)
    this.store.removeById(body.id)

    data.asteroids.push({...body.entity, mass: 0})
    const chunks = 3
    let i = 0
    while(i++ < chunks) {
      const mass = body.entity.mass / chunks
      if (mass < 10) continue
      let ok = false
      let r: Asteroid
      let k = 0;
      do {
        if (k++ > 10) {
          console.log("No space")
          throw new Error("No space");
        }
        const p = V(body.entity.mass/2-mass/2, 0).rotateByRad(Math.random()*2*Math.PI)
        r = makeAsteroid(
          body.entity.frame,
          VectorToObject(V2(body.entity.position).add(p)),
          VectorToObject(p.normalize().mulScalar(30)),
          mass / 2
        )
        // Check if it fits
        ok = !this.collidesWithBall(r.position, r.mass/2)
      } while(!ok)
      // TODO: Hack, must re-update entity from body because of zeroStep
      this.updateAsteroid(this.createAsteroid(r))
      data.asteroids.push(r)
    }
  }

  private crackWall(position: Vector, dir: Vector, data: UpdateData) {
    const wall = createWallPolygon(this.wall);
    //console.assert(wall.isValid(), "Wall polygon is not valid");
    const r = createAsteroidPolygon(position.x, position.y)
    //console.assert(r.isValid(), "Roid polygon is not valid");

    let newWall = Flatten.BooleanOperations.unify(wall, r);
    //console.log(`New wall has ${newWall.faces.size} faces, and ${newWall.splitToIslands().length} islands`);

    // Choose correct face to be the first one
    const faces = [...newWall.faces] as Flatten.Face[];
    faces.sort((a, b) => b.area() - a.area());

    for (const face of faces) {
      console.log(`Face area is ${face.area()}, orientation: ${face.orientation()}, Peri: ${face.perimeter}`);
    }

    // Select the biggest face by area
    const [face, ...rest] = faces
    const points = [...face.edges].map(edge => [edge.start.x, edge.start.y]);
    points.push(points[0]);

    // Reset wall hole to real wall
    this.wall.splice(0, this.wall.length);
    console.assert(this.wall.length === 0);
    Array.prototype.splice.apply(this.wall, [0, 0].concat(points as any) as any);

    // Update the physics collider
    this.wallCollider.setShape(new Polyline(new Float32Array(this.wall.flatMap(x => x))));

    // Add other faces as new roids
    if (rest.length > 0) {
      for (const otherFace of rest) {
        const roid = makeAsteroidFromPolygon(this.frame, otherFace.toPolygon());
        this.createAsteroid(roid);
        data.asteroids.push(roid);
      }
    }
  }

  private createAsteroid(asteroid: Asteroid) {
    const [body, collider] = this.createAsteroidBody(
      asteroid.id,
      asteroid.position,
      asteroid.velocity,
      asteroid.rotation,
      0,
      asteroid.shape)
    const bodyAsteroid: BodyAsteroid = {
      id: asteroid.id,
      body,
      collider,
      type: BodyType.Asteroid,
      entity: asteroid
    };
    this.store.addBody(asteroid.id, bodyAsteroid)
    return bodyAsteroid;
  }

  spawnAsteroid(frame: number, r: number) {
    const velocity = 
      // {x: 0, y: 0}
      VectorToObject(V(Math.random() * 100, 0).rotateByRad(Math.random()*2*Math.PI))

    let i = 0;
    let position;
    let mass;
    let ok = false;
    do {
      if (i++ > 10) {
        console.log("No space")
        throw new Error("No space");
      }
      position = VectorToObject(V(Math.random() * r + 100, 0).rotateByRad(Math.random() * 2 * Math.PI))
      mass = Math.random() * 90 + 10
      ok = !this.collidesWithBall(position, mass/2)
    } while (!ok)

    const asteroid = makeAsteroid(
      frame,
      position,
      velocity,
      mass / 2)
    this.createAsteroid(asteroid)
    this.emit('update', this.CreateData(undefined, undefined, [asteroid]))
    return asteroid
  }

  // Shouldn't really be needed
  private updateShell(s: BodyShell): Shell {
    s.entity.position = {...s.body.translation()}
    s.entity.velocity = {...s.body.linvel()}
    s.entity.ttl = s.entity.ttl - (this.frame - s.entity.frame) / FPS
    s.entity.frame = this.frame
    return s.entity
  }
  getShells(): Shell[] {
    const entities: Shell[] = []
    Array.from(this.store.getAll()).forEach(s => {
      if (s.type === BodyType.Shell) {
        entities.push({...this.updateShell(s)})
      }
    })
    return entities
  }

  private updateShip(s: BodyShip): Ship {
    s.entity.frame = this.frame
    s.entity.position = {...s.body.translation()}
    s.entity.velocity = {...s.body.linvel()}
    s.entity.bearing = s.body.rotation()
    s.entity.rotation = s.body.angvel()
    return s.entity
  }
  getShips(): Ship[] {
    const entities: Ship[] = []
    Array.from(this.store.getAll()).forEach(s => {
      if (s.type === BodyType.Ship) {
        entities.push({...this.updateShip(s)})
      }
    })
    return entities
  }

  private updateAsteroid(s: BodyAsteroid): Asteroid {
    s.entity.frame = this.frame
    s.entity.position = {...s.body.translation()}
    s.entity.velocity = {...s.body.linvel()}
    s.entity.rotation = s.body.angvel()
    s.entity.bearing = s.body.rotation()
    return s.entity
  }

  getAsteroids(): Asteroid[] {
    const entities: Asteroid[] = []
    Array.from(this.store.getAll()).forEach(s => {
      if (s.type === BodyType.Asteroid) {
        entities.push({...this.updateAsteroid(s)})
      }
    })
    return entities
  }

  // Should reset data be current for everything?
  getResetData(): UpdateData {
    return this.CreateData(
      this.getShips(),
      this.getShells(),
      this.getAsteroids())
  }

  private normaliseContact(handle1: ColliderHandle, handle2: ColliderHandle): Contact | undefined {
    const b1 = this.store.getBodyByHandle(handle1)
    const b2 = this.store.getBodyByHandle(handle2)
    if (b1?.type === BodyType.Asteroid && b2?.type === BodyType.Asteroid) {
      return {
        type: ContactType.AsteroidAsteroid,
        asteroid1: b1,
        asteroid2: b2
      }
    }
    if (b1?.type === BodyType.Ship && b2?.type === BodyType.Asteroid) {
      return {
        type: ContactType.AsteroidShip,
        asteroid: b2,
        ship: b1
      }
    }
    if (b1?.type === BodyType.Asteroid && b2?.type === BodyType.Ship) {
      return {
        type: ContactType.AsteroidShip,
        asteroid: b1,
        ship: b2
      }
    }
    if (b1 === undefined && b2?.type === BodyType.Ship) {
      return {
        type: ContactType.ShipWall,
        ship: b2
      }
    }
    if (b1?.type === BodyType.Ship && b2 === undefined) {
      return {
        type: ContactType.ShipWall,
        ship: b1
      }
    }
    if (b1 === undefined && b2?.type === BodyType.Asteroid) {
      return {
        type: ContactType.AsteroidWall,
        asteroid: b2
      }
    }
    if (b1?.type === BodyType.Asteroid && b2 === undefined) {
      return {
        type: ContactType.AsteroidWall,
        asteroid: b1
      }
    }
  }

  private normaliseCollision(handle1: ColliderHandle, handle2: ColliderHandle): Collision | undefined {
    const b1 = this.store.getBodyByHandle(handle1)
    const b2 = this.store.getBodyByHandle(handle2)
    if (b1?.type === BodyType.Shell && b2?.type === BodyType.Ship) {
      return {
        type: CollisionType.ShellShip,
        shell: b1,
        ship: b2
      }
    }
    if (b1?.type === BodyType.Ship && b2?.type === BodyType.Shell) {
      return {
        type: CollisionType.ShellShip,
        shell: b2,
        ship: b1
      }
    }
    if (b1?.type === BodyType.Shell && b2?.type === BodyType.Asteroid) {
      return {
        type: CollisionType.ShellAsteroid,
        shell: b1,
        asteroid: b2
      }
    }
    if (b1?.type === BodyType.Asteroid && b2?.type === BodyType.Shell) {
      return {
        type: CollisionType.ShellAsteroid,
        shell: b2,
        asteroid: b1
      }
    }
    if (handle1 === this.wallCollider.handle && b2?.type === BodyType.Shell) {
      return {
        type: CollisionType.ShellWall,
        shell: b2,
      }
    }
    if (b1?.type === BodyType.Shell && handle2 === this.wallCollider.handle) {
      return {
        type: CollisionType.ShellWall,
        shell: b1,
      }
    }
  }


  step() {
    this.frame++
    this.world.step(this.queue)
    // Limit speed of all bodies
    // this.world.bodies.forEach(body => {
    //   const v = V2(body.linvel())
    //   if (v.magnitude() > MAX_VELOCITY) {
    //     const limitedV = v.normalize().mulScalar(MAX_VELOCITY)
    //     body.setLinvel(VectorToObject(limitedV), true)
    //   }
    // })
    const data = this.CreateData();
    let updated = false
    this.queue.drainCollisionEvents((handle1, handle2, started) => {
      if (!started) return
      const collision = this.normaliseCollision(handle1, handle2);

      switch (collision?.type) {
        case CollisionType.ShellShip:
          this.updateShell(collision.shell)

          // Safe arming distance of shell
          if (collision.shell.entity.ttl > (MAX_SHELL_TTL - MAX_SHELL_TTL / 10) &&
              collision.ship.id === collision.shell.entity.owner) {
            break;
          }
          console.log(`Hit ship ${collision.ship.id}`)
          updated = true
          collision.shell.entity.ttl = 0
          data.shells.push(collision.shell.entity)
          data.hits.push({
            owner: collision.shell.entity.owner,
            position: {...collision.shell.body.translation()},
            vector: {...collision.shell.body.linvel()},
            target: collision.ship.id,
            type: BodyType.Ship
          })
          break
        case CollisionType.ShellAsteroid:
          console.log(`Hit roid ${collision.asteroid.id}`)
          updated = true
          collision.shell.entity.ttl = 0
          data.shells.push(this.updateShell(collision.shell))
          const hit: ShellHit = {
            owner: collision.shell.entity.owner,
            position: {...collision.shell.body.translation()},
            vector: {...collision.shell.body.linvel()},
            target: collision.asteroid.id,
            type: BodyType.Asteroid
          };
          data.hits.push(hit)
          this.crackPolygonAsteroid(collision.asteroid, hit, data)
          break
        case CollisionType.ShellWall:
          console.log(`Hit Wall`)

          // Get a more accurate reading on the collision point
          const ray = new Ray(collision.shell.body.translation(), collision.shell.body.linvel())
          const rayHit = this.world.castRay(ray, 10, true, undefined, undefined, collision.shell.collider);
          // TODO: Figure out why this is sometimes null
          const position = rayHit != null
              ? ray.pointAt(rayHit!.toi)
              : {...collision.shell.body.translation()};

          updated = true
          collision.shell.entity.ttl = 0
          data.shells.push(this.updateShell(collision.shell))
          data.hits.push({
            owner: collision.shell.entity.owner,
            position: {...position},
            vector: {...collision.shell.body.linvel()},
            target: "wall",
            type: BodyType.Wall
          })
          this.crackWall(
            position,
            collision.shell.body.linvel(),
            data)
          break
        default:
          break;
      }
    })
    this.queue.drainContactForceEvents(e => {
      const contact = this.normaliseContact(e.collider1(), e.collider2());
      switch (contact?.type) {
        case ContactType.AsteroidAsteroid:
          data.asteroids.push(
            this.updateAsteroid(contact.asteroid1),
            this.updateAsteroid(contact.asteroid2))
          updated = true
          break
        case ContactType.AsteroidShip:
          data.ships.push(this.updateShip(contact.ship))
          data.asteroids.push(this.updateAsteroid(contact.asteroid))
          updated = true
          break
        case ContactType.AsteroidWall:
          data.asteroids.push(this.updateAsteroid(contact.asteroid))
          updated = true
          break
        case ContactType.ShipWall:
          data.ships.push(this.updateShip(contact.ship))
          updated = true
          break
        default:
          throw new Error("Unknown contact force object")
      }
    })

    Array.from(this.store.getAll()).forEach(body => {
      // Correct all thrusting ships
      if (body.type === BodyType.Ship && body.entity.thrust > 0) {
        const ship = body.entity
        // Calculate acceleration per step/frame
        const thrustVector = new Vecta(ship.thrust / FPS, 0)
          .rotateByRad(body.body.rotation())
          .add(Vecta.fromObject(body.body.linvel()))
        body.body.setLinvel(VectorToObject(thrustVector), true);
      }
      // Move Shell positions
      if (body.type === BodyType.Shell) {
        // Filter out old shells
        if (body.entity.ttl - (this.frame - body.entity.frame) / FPS < 0) {
          this.world.removeRigidBody(body.body)
          this.store.removeById(body.id)
        }
      }
      // Remove massless asteroids
      if (body.type === BodyType.Asteroid && body.entity.mass === 0) {
        this.world.removeRigidBody(body.body)
        this.store.removeById(body.id)
      }
    })

    if (updated) {
      this.emit('update', data)
    }

    // if (this.getAsteroids().length < 10) {
    //   this.spawnAsteroid(this.frame, 150)
    // }
  }

  command(id: string, command: Command) {
    if (command === Command.SPAWN) {
      this.createShip(id, this.frame)
    }
    const body = this.store.getBodyById(id)
    if (body?.type !== BodyType.Ship) {
      return
    }
    const ship: Ship = this.updateShip(body)
    ship.frame = this.frame
    ship.position = {...body.body.translation()}
    ship.velocity = {...body.body.linvel()}
    ship.rotation = body.body.angvel()
    ship.bearing = body.body.rotation()
    switch (command) {
      case Command.THRUST_START: ship.thrust = SHIP_THRUST; break
      case Command.THRUST_END: ship.thrust = 0; break
      case Command.TURN_LEFT: ship.rotation = -SHIP_ROTATION; break
      case Command.TURN_RIGHT: ship.rotation = SHIP_ROTATION; break
      case Command.TURN_END: ship.rotation = 0; break
      case Command.FIRE: {
        const shell = newShell(ship, this.frame)
        this.createShell(shell)
        this.emit('update', this.CreateData([ship], [shell]))
        return
      }
    }
    switch (command) {
      case Command.TURN_LEFT:
      case Command.TURN_RIGHT:
      case Command.TURN_END:
        body.body.setAngvel(ship.rotation, true)
        break;
    }
    this.emit('update', this.CreateData([ship]))
  }

  private CreateData(
    ships?: Ship[],
    shells?: Shell[],
    asteroids?: Asteroid[],
  ): UpdateData {
    return {
      frame: this.frame,
      ships: ships ?? [],
      shells: shells ?? [],
      wall: this.wall,
      hits: [],
      asteroids: asteroids ?? [],
    }
  }
}
