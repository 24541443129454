// Fudge factor to ensure objects don't collide
export const COLLISION_FUDGE = 0
export const COLLISION_RADIUS = 5
export const SHELL_VELOCITY = 120
export const MAX_VELOCITY = SHELL_VELOCITY * 2
export const SHELL_COLLISION_RADIUS = 2
export const FPS = 60
export const FRAME_SIZE = 1000 / FPS

// Rotation in Radians per second
export const SHIP_ROTATION = Math.PI

// Velocity in pixel/s^2
export const SHIP_THRUST = 5*60
