import * as React from "react";
import {useKeyPress} from "../util";
import {Command} from "../simulator/command";

const MoveTo: React.FC<{
  command: (c: Command) => void
}> = ({command}) => {
  const up = useKeyPress('ArrowUp')
  const left = useKeyPress('ArrowLeft')
  const right = useKeyPress('ArrowRight')
  const space = useKeyPress(" ")

  React.useEffect(() => {
    if (up === undefined) return;
    if (up) {
      command(Command.THRUST_START);
    } else {
      command(Command.THRUST_END);
    }
  }, [command, up])
  React.useEffect(() => {
    if (left === undefined) return;
    if (left ) {
      command(Command.TURN_LEFT);
    } else {
      command(Command.TURN_END);
    }
  }, [command, left])
  React.useEffect(() => {
    if (right === undefined) return;
    if (right) {
      command(Command.TURN_RIGHT);
    } else {
      command(Command.TURN_END);
    }
  }, [command, right])
  React.useEffect(() => {
    if (space) {
      command(Command.FIRE);
    }
  }, [command, space])

  return (<></>)
};

export { MoveTo }
