import Flatten, {
    point,
    vector,
    circle,
    line,
    ray,
    segment,
    arc,
    Polygon,
    Face,
    Point,
    ORIENTATION
} from '@flatten-js/core';
import {generateWall, Wall} from "./simulator";
import {Asteroid, makeAsteroid, makeId} from "./model/asteroid";

export function createWallPolygon(wall: Wall): Polygon {
    const points = wall.map(([x, y]) => point(x, y))

    const polygon = new Polygon();
    const face = polygon.addFace(points);
    face.reverse()
    if (face.orientation() !== Flatten.ORIENTATION.CCW) {
        face.reverse()
    }
    return polygon;
}

export function createPolygonFromAsteroid(asteroid: Asteroid) {
    const polygon = new Polygon();
    const points = asteroid.shape.map(x => point(x[0], x[1]));
    const face = polygon.addFace(points);
    face.reverse()
    if (face.orientation() !== Flatten.ORIENTATION.CCW) {
        face.reverse()
    }
    return polygon;
}

export function createAsteroidPolygon(x: number, y: number): Polygon {
    const asteroid = new Polygon();
    const r = generateWall(20, 20, Math.random())
        .map(p => point(p[0], p[1]));
    const face = asteroid.addFace(r)

    face.reverse()
    if (face.orientation() !== Flatten.ORIENTATION.CCW) {
        face.reverse()
    }
    return asteroid.translate(vector(x, y));
}

export function makeAsteroidFromPolygon(frame: number, polygon: Polygon): Asteroid {
    const centre = vector(point(), polygon.box.center)
    const p = polygon.translate(centre.invert());
    const shape = [...[...p.faces][0].edges].map((e: Flatten.Edge) => [e.start.x, e.start.y] as [x: number, y: number]);
    const id = makeId()
    return {
        id,
        frame,
        mass: p.area(),
        position: {x: centre.x, y: centre.y},
        velocity: {x: 0, y: 0},
        rotation: 0,
        bearing: 0,
        shape: shape,
    }
}

export function calculateMassProperties(wall: Wall) {
    const p = new Polygon(wall.map(([x, y]) => point(x, y)));

    // MOI
    const factor = 1.0 / 6.0;
    // Loop through each triangle:
    let itot = 0;
    for (const face of [...p.faces] as Flatten.Face[]) {
        let ipart = 0;
        for (const edge of face.edges) {
            // form a triangle with com
            const a = vector(point(), p.box.center);
            const b = vector(point(), edge.start);
            const c = vector(point(), edge.end);

            // Algorithm adapted from Box2D
            let e1 = b.subtract(a);
            let e2 = c.subtract(a);

            let intx2 = e1.x * e1.x + e2.x * e1.x + e2.x * e2.x;
            let inty2 = e1.y * e1.y + e2.y * e1.y + e2.y * e2.y;
            ipart = factor * (intx2 + inty2)
        }
        itot += ipart * face.area();
    }


    return {
        mass: p.area(),
        center: p.box.center,
        momentOfInertia: itot,
    }
}

export function intersects(wall: Wall, r: Flatten.Point[]) {
    let p_wall = new Polygon([
        point(-1000, -1000),
        point(-1000, 1000),
        point(1000, 1000),
        point(1000, -1000)
    ]);

    const wall_points = wall.map(([x, y]) => point(x, y));
    //const f = new Face();
    p_wall.addFace(wall_points);

    const p_r = new Polygon(r);

    const x = p_r.intersect(p_wall);
    const a = Flatten.BooleanOperations.intersect(p_wall, p_r);
    return a;
}