import {Vecta} from "vecta";

export interface Vector {
  x: number,
  y: number,
}

export function V(x: number, y: number): Vecta {
  return new Vecta(x, y)
}
export function V2(v: {x: number, y: number}): Vecta {
  return new Vecta(v.x, v.y)
}
export function Va(v: [x: number, y: number]): Vecta {
  return new Vecta(v[0], v[1]);
}
export function VectorToObject(vector: Vecta) {
  return {
    x: vector.getX(),
    y: vector.getY(),
  }
}
export function VectorToArray(vector: Vector) {
  return [vector.x, vector.y]
}
