import {COLLISION_RADIUS, FPS, FRAME_SIZE, SHELL_VELOCITY} from "../constants";
import {Ship} from "./ship";
import {V, V2, Vector, VectorToObject} from "./vector";
import {BodyType} from "../RapierSimulator";
export const MAX_SHELL_TTL = 3; // Starting TTL of a shell

export interface Shell {
  id: number
  frame: number
  owner: string
  position: Vector
  velocity: Vector
  ttl: number
  hitId?: string
}

export type ShellHit = {
  owner: string
  position: Vector
  vector: Vector
  target: string
  type?: BodyType
}

export const newShell: (s: Ship, frame: number) => Shell = (ship, frame) => {
  const shipVelocity = V2(ship.velocity)
  const shellVelocity = V(SHELL_VELOCITY, 0).rotateByRad(ship.bearing)
  const shellVector = shellVelocity.add(shipVelocity)

  const shellPosition = V(0, 0).rotateByRad(ship.bearing).add(V2(ship.position))
  return {
    id: Math.random() * Number.MAX_SAFE_INTEGER,
    frame,
    owner: ship.id,
    position: VectorToObject(shellPosition),
    velocity: VectorToObject(shellVector),
    ttl: MAX_SHELL_TTL,
  }
}

export function projectShellInTime(shell: Shell, frame: number): Shell {
  const delta = (frame - shell.frame) / FPS;
  shell.frame = frame
  const shellPosition = V2(shell.position).add(V2(shell.velocity).mulScalar(delta))
  shell.position = VectorToObject(shellPosition)
  shell.ttl -= (delta);
  return shell;
}
