import {FPS} from "../constants";
import {Vector, V, VectorToObject, V2} from "./vector";

export interface Ship {
  id: string,
  frame: number,
  bearing: number,
  position: Vector,
  velocity: Vector,
  thrust: number,
  rotation: number,
}

function randomVector(r: number) {
  const v = V(r, 0).rotateByRad(Math.random()*2*Math.PI)
  return {
    x: v.getX(),
    y: v.getY(),
  }
}

export const newShip: (
  id: string, frame: number, r: number
) => Ship = (
  id, frame, r
) => ({
  id,
  frame,
  bearing: Math.random()*2*Math.PI,
  position: randomVector(r),
  thrust: 0,
  rotation: 0,
  // velocity: {x: 0, y: 0},
  //bearing: -Math.PI/2,
  // bearing: 0,
  // position: {x: 0, y: 0},
  velocity: {x: 0, y: 0},
  // thrust: 1 / FPS, // 1 unit per second
  // rotation: -(1 / FPS), // 1 RPM
})

export function projectShipInTime(oShip: Ship, frame: number): Ship {
  const ship = {...oShip};
  const delta = (frame - ship.frame) / FPS // Seconds

  ship.frame = frame

  const shipVelocity = V2(ship.velocity)
  const shipPosition = V2(ship.position)
  // TODO: Remove this conditional ?
  if (ship.rotation !== 0 && ship.thrust > 0) {
    const wt = ship.rotation * delta;
    const acceleration = V(ship.thrust, 0)

    const p = acceleration.mulScalar(1/Math.pow(ship.rotation, 2))
      .add(V(
        -acceleration.getY()/ship.rotation,
        acceleration.getX()/ship.rotation
      ).mulScalar(delta))
      .sub(V(
        Math.cos(wt)*acceleration.getX()-Math.sin(wt)*acceleration.getY(),
        Math.sin(wt)*acceleration.getX()+Math.cos(wt)*acceleration.getY()
      ).mulScalar(1/Math.pow(ship.rotation, 2)))

    ship.position = VectorToObject(
      shipPosition
        .add(p.rotateByRad(ship.bearing))
        .add(shipVelocity.mulScalar(delta)))
    ship.velocity = VectorToObject(acceleration
      .rotateByRad(wt)
      .mulScalar(1/ship.rotation))
  } else if (ship.thrust > 0) {
    const thrustVector = V(ship.thrust, 0).rotateByRad(ship.bearing)
    // Distance travelled under acceleration
    // D = (v * t) + (1/2 * a * t^2)
    const distance = shipVelocity
      .mulScalar(delta)
      .add(thrustVector
        .divScalar(2)
        .mulScalar(Math.pow(delta, 2)))
    ship.position = VectorToObject(shipPosition.add(distance))

    const newVel = shipVelocity.add(thrustVector.mulScalar(delta))
    ship.velocity = VectorToObject(newVel);
  } else {
    ship.position = VectorToObject(shipPosition.add(shipVelocity.mulScalar(delta)))
  }

  ship.bearing = ship.bearing + (ship.rotation * delta)
  return ship
}
