import * as React from "react";
import {Canvas} from "./components/Canvas";
import {CanvasSize} from "./components/CanvasSize";
import {MainView, useAnimationFrame} from "./components/MainView";
import {Minimap} from "./components/Minimap";
import {MoveTo} from "./components/MoveTo";
import {Debug} from "./Debug";
import {World} from "./service/World";
import {Ship} from "./simulator/model/ship";
import {Command} from "./simulator/command";
import {RapierSimulator} from "./simulator/RapierSimulator";
import RAPIER from "@dimforge/rapier2d-compat";
import {UpdateData} from "./simulator/simulator";
import {Asteroid, makeAsteroid} from "./simulator/model/asteroid";

export interface Data {
  time: number
  id: string
  command: Command
  aux?: Ship
}

//const url = window.location.hostname === "localhost" ? `ws://${window.location.hostname}:2096` : "wss://a2.yarekt.co.uk:2096";
//const socket: Socket<ServerToClientEvents, ClientToServerEvents> = io(url);

function useWorld() {
  const [everything, setEverything] = React.useState<any>()
  React.useEffect(() => {
    const world = new World();
    const simulator = new RapierSimulator(0);
    const socket = {
      id: "test",
      command(command: Command) {
        //console.log(`Command:`, Command[command])
        //await new Promise(r => setTimeout(r, 10 + Math.random() * 1000))
        //const frame = timeToFrame(Date.now())
        const frame = simulator.frame + 1
        //simulator.command(socket.id, frame, command)
        // Save stuff after command is processed
        //const [ships, shells] = 
          simulator.command(socket.id, command)
        // ships.map(s => simulator.updateShip(s))
        // simulator.updateShells(shells)

        // const commandData = {
        //   id: socket.id,
        //   frame,
        //   data: command,
        //   ships,
        //   asteroids: [],
        //   shells,
        //   wall: [],//simulator.wall,
        //   hits: [],
        // }
        // console.log("Sending command", commandData)
        //world.update(commandData)
      }
    }

    // Bootstrap
    simulator.on('update', (data: UpdateData) => {
      world.update(data)
    })
    world.update(simulator.getResetData());

    // simulator.connect(socket.id, timeToFrame(Date.now()))
    simulator.command(socket.id, Command.SPAWN);

    /*
    socket.on('remove_ship', id => {
      // world.removeShip(id)
    })

    // Connect
    socket.on('connect', () => {
      console.log(`My id is ${socket.id}`)

      // Connect spawns ship, need to send it over network somehow
      socket.emit('bootstrap', reset => {
        console.log(`Reset:`, reset)
        world.update(reset);
        socket.emit('request_event', Command.SPAWN)
      })
    })

    // Disconnect
    socket.on('disconnect', () => {
      socket.off('response_event')
      socket.off('remove_ship')
    })
    */
    setEverything({
      playerId: socket.id,
      socket,
      world,
      simulator
    })
  }, []);
  return [everything] as const;
}

const useRapier = () => {
  const [ready, setReady] = React.useState(false)
  React.useEffect(() => {
    RAPIER.init().then(() => setReady(true))
  }, [])
  return ready
}

export const Rapier: React.FC = () => {
  const ready = useRapier()
  if (!ready) {
    return <p>Loading RAPIER engine...</p>
  }
  return <WorldWrapper />;
}

export const WorldWrapper: React.FC = () => {
  const [s] = useWorld()
  if (!s) {
    return <p>World not ready...</p>
  }
  return <App s={s}/>
}

const App: React.FC<{s: any}> = ({s}) => {
  const {playerId, socket, world, simulator} = s

  useAnimationFrame(_ => {
    simulator.step()
    // DEBUG: Update the world fully every step
    // world.update(simulator.getResetData())
  })

  // const [playerShip, setPlayerShip] = React.useState<Ship | null>(null)
  const debug = new Debug()

  // React.useEffect(() => {
  //   if (playerId) {
  //     const ship = world.getPlayerShip(playerId)
  //     if (ship) {
  //       setPlayerShip(ship)
  //     } else {
  //       console.log(`Player ship for ${playerId} not found`)
  //     }
  //   }
  // }, [playerId])

  // Create one roid for testing
  const asteroid = makeAsteroid(
    simulator.frame,
    {x: -100, y: 0},
    {x: 10, y: 0},
    50);
  simulator.createAsteroid(asteroid)
  simulator.emit('update', simulator.CreateData(undefined, undefined, [asteroid]))
  simulator.command(playerId, Command.FIRE);


  // setInterval(() => {
  //   if (simulator.getAsteroids().reduce((s: number, x: Asteroid) => s + x.mass, 0) < 300) {
  //     simulator.spawnAsteroid(simulator.frame + 1, 150)
  //   }
  // }, 1000)
  // TODO: ClientSide simulation: Server is required to process collisions client side
  // let lastFrame = timeToFrame(Date.now())
  // setInterval(() => {
  //   const thisFrame = timeToFrame(Date.now())
  //   if (thisFrame > lastFrame) {
  //     world.update(server.step(thisFrame))
  //     lastFrame = thisFrame
  //     console.log(`World Frame: ${lastFrame}`)
  //   }
  // }, FPS)
  if (!world || !socket) {
    return (<p>Loading network ...</p>)
  }
  if (!playerId) {
    return (<p>Loading player ...</p>)
  }
  if (!world.getPlayerShip(playerId)) {
    return (<p>Loading player ship ... {JSON.stringify(world.data.ships)}</p>)
  }

  return (
    <>
      {/*<MatterComponent sim={simulator} />*/}
      <Canvas>
        {(canvas) =>
          <>
            {/*<CanvasSize canvas={canvas}>*/}
            {/*  {({width, height}) =>*/}
            {/*    <Minimap*/}
            {/*      width={width}*/}
            {/*      height={height}*/}
            {/*      getPlayerShip={() => world.getPlayerShip(playerId)}*/}
            {/*    />*/}
            {/*  }*/}
            {/*</CanvasSize>*/}
            <MainView
              canvas={canvas}
              world={world}
              debug={debug}
              playerId={playerId}
            />
          </>
        }
      </Canvas>
      <MoveTo command={c => {
        socket.command(c)
      }} />
    </>
  )
};

export default App;
