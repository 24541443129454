import {timeToFrame} from "./simulator/simulator";

export interface DebugData {
  simulationTime: number,
  sendTime: number,
  frameGap: number,
  frame: number,
}

export class Debug {
  // Debug
  _simTimeArray = new Array<number>()
  _sendTimeArray = new Array<number>()
  _debug = "Debug"

  debug() {
    return this._debug
  }

  rp(n: number) {
    return String(Math.round(n)).padStart(4)
  }

  update(data: DebugData) {
    const frameDelta = timeToFrame(Date.now()) - data.frame

    this._simTimeArray.unshift(data.simulationTime)
    if (this._simTimeArray.length > 60) {
      this._simTimeArray.pop()
    }
    const simTime = this._simTimeArray.reduce((accu, i) => accu + i, 0) / 60

    this._sendTimeArray.unshift(data.sendTime)
    if (this._sendTimeArray.length > 60) this._sendTimeArray.pop()
    const sendTime = this._sendTimeArray.reduce((a, i) => a + i, 0) / 60
    this._debug = `Delay: ${this.rp(frameDelta)}ns Simulation time: ${this.rp(data.simulationTime)}ns (${this.rp(simTime)}ns) ${this.rp(simTime / 16.6 * 100)}%, sendTime: ${this.rp(data.sendTime)}ns (${this.rp(sendTime)}ns) ${this.rp(sendTime / 1000 / 16.6 * 100)}%, Inter-frame delay: ${this.rp(data.frameGap)}ns`
  }
}
