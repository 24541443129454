import {Vecta} from "vecta/dist";
import {Vector, VectorToObject} from "./vector";
import {FPS} from "../constants";
import {generateWall, Wall} from "../simulator";

export interface Asteroid {
  id: string
  frame: number
  position: Vector
  velocity: Vector
  bearing: number
  rotation: number
  mass: number // Diameter // @TODO: Change to calculate mass
  shape: Wall
}

export const makeAsteroid = (
  frame: number,
  position: Vector,
  velocity: Vector,
  radius: number
): Asteroid => {
  const id = makeId() 
  return {
    id,
    frame,
    mass: radius * 2,
    position: position,
    velocity: velocity,
    rotation: 0,
    bearing: 0,
    shape: generateWall(radius, 20, id)
  }
}

export const randomVector = (radius: number) => {
  return new Vecta(Math.random() * radius, 0).rotateByRad(Math.random()*2*Math.PI)
}

export function makeId(): string {
  return String((Math.random() * Number.MAX_SAFE_INTEGER))
}

export const randomPositionWithinRadius = (position: Vecta, radius: number): Vecta => {
  const f = new Vecta(radius, 0).rotateByRad(Math.random()*2*Math.PI)
  return position.add(f)
}

export function projectAsteroidInTime(oAsteroid: Asteroid, frame: number): Asteroid {
  const asteroid = {...oAsteroid};
  const delta = (frame - asteroid.frame)
  if (delta === 0) return asteroid;

  asteroid.frame = frame

  const velocity = Vecta.fromObject(asteroid.velocity)
  const position = Vecta.fromObject(asteroid.position)
  asteroid.position = VectorToObject(position.add(velocity.mulScalar(delta / FPS)))
  asteroid.bearing = asteroid.bearing + (asteroid.rotation * delta / FPS)
  return asteroid
}
