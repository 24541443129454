import {FRAME_SIZE} from "./constants";
import {Asteroid} from "./model/asteroid";
import {ShellHit, Shell} from "./model/shell";
import {Ship} from "./model/ship";

import Alea from "alea";
import {Vector} from "@dimforge/rapier2d-compat";
import {V2, Va} from "./model/vector";

export function frameToTime(frame: number): number {
  return frame * FRAME_SIZE
}
export function timeToFrame(timeMs: number): number {
  return Math.floor(timeMs / FRAME_SIZE)
}

export interface UpdateData {
  frame: number
  ships: Ship[]
  asteroids: Asteroid[]
  shells: Shell[]
  hits: ShellHit[]
  wall: Wall
}

export type Wall = [x: number, y: number][]
export function generateWall(radius: number = 500, points: number = 40, seed: any = null): Wall {
  const random = Alea(seed ?? 42);
  const wall: Wall = [];
  for (let i = 0; i < points; i++) {
    const a = i * Math.PI * 2 / points + 40
    let f = (random() - 0.5) * radius / 10;
    let x = (radius + f) * Math.cos(a);
    let y = (radius + f) * Math.sin(a);
    wall.push([x, y]);
  }
  wall.push(wall[0]);
  return wall;
}

export function generateSimpleWall(radius: number = 500, points: number = 40, seed: any = null): Wall {
  const random = Alea(seed ?? 42);
  const wall: Wall = [];
  // for (let i = 0; i < points; i++) {
  //   const a = i * Math.PI * 2 / points + 40
  //   let f = (random() - 0.5) * radius / 3.5;
  //   let x = (radius + f) * Math.cos(a);
  //   let y = (radius + f) * Math.sin(a);
  //   wall.push([x, y]);
  // }
  // wall.push(wall[0]);
  const border = 200
  wall.push(
    [border, -border],
    [-border, -border],
    [-border, border],
    [border, border],
    [border, -border+1],
  )
  return wall;

  // return [[-500, -300], [-500, 500], [400, 400], [500, -500]];
}

export function findSegment(wall: Wall, position: Vector): number {
  const p = V2(position)
  for (let i = 0; i < wall.length; i++) {
    const i2 = (i + 1) % wall.length
    const p1 = Va(wall[i])
    const p2 = Va(wall[i2])
    const totalDistance = p1.distanceTo(p2)
    const ourDistance = p1.distanceTo(p) + p2.distanceTo(p)
    if (Math.abs(totalDistance - ourDistance) < 0.2) {
      return i2
    }
  }
  throw Error()
}
